<template>
  <div>
    <client-list-filter
      :user-filter.sync="userFilter"
      :user-options="userOptions"
      :client-options="clientOptions"
      :groupcode-options="groupcodeOptions"
      :client-filter.sync="clientFilter"
      :groupcode-filter.sync="groupcodeFilter"
    />
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="搜索客戶..."
              />
              <b-button
                variant="primary"
                @click="addClient"
                v-if="ability.can('create', 'clients')"
                :to="{ name: 'clients-create' }"
              >
                <span class="text-nowrap">新增客戶</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refClientListTable"
        class="position-relative"
        :items="fetchClients"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Client Code -->
        <template #cell(client_code)="data">
          <b-link
            :to="{ name: 'clients-detail', params: { id: data.item.id } }"
            >{{ data.item.client_code }}</b-link
          >
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="ability.can('update', 'clients')"
              :to="{ name: 'clients-edit', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalClients"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import axiosIns from "@/libs/axios";
import clientStoreModule from "../clientStoreModule";
import invoiceStoreModule from "@/views/invoice/invoiceStoreModule";
import useClientsList from "./useClientsList";
import ClientListFilter from "./ClientListFilter";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ClientListFilter,
  },
  methods: {
    editMenu(id) {},
    confirmDelete(id) {
      this.$swal({
        title: "確認刪除?",
        text: "你的操作是最終的，你將無法查閱該客戶",
        showCancelButton: true,
        confirmButtonText: "刪除",
        cancelButtonText: '取消',
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("app-client/deleteClient", { client_id: id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    addClient() {
      this.isAddNewClientSidebarActive = true;
    },
  },
  setup() {
    const APP_CLIENT_STORE_MODULE_NAME = "app-client";
    const INVOICE_STORE_MODULE_NAME = "invoice";

    const isAddNewClientSidebarActive = ref(false);

    if (!store.hasModule(APP_CLIENT_STORE_MODULE_NAME))
      store.registerModule(APP_CLIENT_STORE_MODULE_NAME, clientStoreModule);
    if (!store.hasModule(INVOICE_STORE_MODULE_NAME))
      store.registerModule(INVOICE_STORE_MODULE_NAME, invoiceStoreModule);

    onUnmounted(() => {
      if (store.hasModule(APP_CLIENT_STORE_MODULE_NAME))
        store.unregisterModule(APP_CLIENT_STORE_MODULE_NAME);
      if (store.hasModule(INVOICE_STORE_MODULE_NAME))
        store.unregisterModule(INVOICE_STORE_MODULE_NAME);
    });

    const {
      fetchClients,
      tableColumns,
      perPage,
      currentPage,
      totalClients,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refClientListTable,
      ability,
      userFilter,
      clientFilter,
      groupcodeFilter,
    } = useClientsList();

    //Filter
    const userOptions = ref([]);
    const clientOptions = ref([]);
    const groupcodeOptions = ref([]);

    store
      .dispatch("app/fetchOptionList", {
        client: true,
        group_code: true,
        user: true,
      })
      .then((response) => {
        clientOptions.value = response.data.clients;
        groupcodeOptions.value = response.data.group_codes;
        userOptions.value = response.data.users;
      })
      .catch((error) => {
        console.log(error);
      });

    return {
      isAddNewClientSidebarActive,
      fetchClients,
      tableColumns,
      perPage,
      currentPage,
      totalClients,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refClientListTable,
      ability,
      clientOptions,
      userOptions,
      groupcodeOptions,
      userFilter,
      clientFilter,
      groupcodeFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.badge {
  display: block;
  margin: 5px;
  max-width: 200px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
